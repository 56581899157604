export const blog = 'https://www.blog.ryco.io'

export const linkedIn = 'https://www.linkedin.com/company/rycoio/'
export const twitter = 'https://twitter.com/rycoio'
export const instagram = 'https://www.instagram.com/ryco.io/'
export const facebook = 'https://www.facebook.com/ryco.io'

export const joinTheTeamLink =
  'https://forms.clickup.com/14181880/f/dgtfr-10420/6NCD1ONDE5EBO4V1HC'

export const HUBSPOT_RILEY = 'https://meetings.hubspot.com/riley-walker'
