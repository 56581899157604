import * as React from 'react'
import { useTrackingCode } from 'react-hubspot-tracking-code-hook'

import Avatars from 'components/Avatars'
import BackgroundOverlay from 'components/BackgroundOverlay'
import JoinTheTeam from 'components/JoinTheTeam'
import ScrollText from 'components/ScrollText'
import ScrollToTopButton from 'components/ScrollToTopButton'
import Testimonials from 'components/Testimonials'
import { TabletAndBelow } from 'styles'

import AboutUs from './AboutUs'
import Blog from './Blog'
import LDTeams from './LDTeams'
import Services from './Services'
import ValueProp from './ValueProp'
import { Header, SubHeader, Tagline } from './styled-components'

const Home = () => {
  const { setPathPageView } = useTrackingCode()

  React.useEffect(() => {
    setPathPageView('/home')
  }, [setPathPageView])

  return (
    <>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ScrollToTopButton />
        <Header>
          Custom Content{' '}
          <TabletAndBelow>
            <br />
          </TabletAndBelow>{' '}
          for Classrooms
        </Header>
        <SubHeader>
          Time back for teachers.{' '}
          <TabletAndBelow>
            <br />
          </TabletAndBelow>{' '}
          Success for Schools.
        </SubHeader>
        <Tagline>TELL US WHO YOU ARE:</Tagline>
        <Avatars />
        <BackgroundOverlay />
      </div>

      {/* TODO: refactor but for now this saves us from the scroll text overflowing */}
      <div style={{ overflow: 'hidden' }}>
        <ValueProp />
        <ScrollText />
        <Services />
        <LDTeams />
        <AboutUs />
        <Blog />
        <Testimonials />
        <JoinTheTeam />
      </div>
    </>
  )
}

export default Home
