import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { colors } from 'styles/colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.yellow};
  align-items: center;
  justify-content: center;
  padding: 51px 40px 0 40px;
  text-align: center;

  ${up('lg')} {
    padding: 85px 122px 98px 122px;
    text-align: left;
    align-items: flex-start;
    max-height: 450px;
    flex-wrap: wrap;
    flex: 1;
    position: relative;
  }
`

export const Header = styled.h1`
  margin: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 75px */
  color: ${colors.black};
  font-size: 28px;

  ${up('lg')} {
    font-size: 50px;
  }
`

export const SubHeader = styled.h2`
  margin: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  color: ${colors.black};
  font-size: 16px;
  margin-top: 21px;

  ${up('lg')} {
    margin-top: 36px;
    font-size: 18px;
    max-width: 470px;
  }
`

export const Button = styled.a<{ alt?: string; description?: string }>`
  text-decoration: none;
  background: radial-gradient(
      83.94% 83.94% at 26.39% 20.83%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    ),
    #000;
  border-radius: 109px;
  color: ${colors.white};

  height: 55px;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(75% + 80px);

  ${up('lg')} {
    width: 300px;
    height: 64px;
    :first-of-type {
      margin-top: 64px;
    }
  }
`

export const Image = styled.img`
  width: 270px;
  height: 129px;
  margin-top: 19px;

  ${up('lg')} {
    margin-top: 5px;
    width: 628px;
    height: 310px;
    position: absolute;
    bottom: 0;
    right: 120px;

    @media (max-width: 1250px) {
      right: 10px;
      width: 471px;
      height: 232px;
    }
  }
`
