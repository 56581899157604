import educator from 'assets/videos/educatorAvatar/educator.webm'
import educatorBackup from 'assets/videos/educatorAvatar/educatorBackup.mov'
import ldDirector from 'assets/videos/ldDirectorAvatar/ldDirector.webm'
import ldDirectorBackup from 'assets/videos/ldDirectorAvatar/ldDirectorBackup.mov'
import publisher from 'assets/videos/publisherAvatar/publisher.webm'
import publisherBackup from 'assets/videos/publisherAvatar/publisherBackup.mov'
import schoolDirector from 'assets/videos/schoolDirectorAvatar/schoolDirector.webm'
import schoolDirectorBackup from 'assets/videos/schoolDirectorAvatar/schoolDirectorBackup.mov'
import { colors, isSafariOrIosChrome } from 'styles'

const questions = {
  schools: [
    {
      question: 'What grade levels? Check all that apply.',
      multiSelect: [
        'PreK',
        'K-2nd',
        '3rd-5th',
        '6th-8th',
        '9th-12th',
        'College or Graduate Studies',
        'Adult Training',
      ],
    },
    {
      question: 'What content areas? Check all that apply.',
      multiSelect: [
        'English / Language Arts',
        'Mathematics',
        'Science',
        'Social Studies',
        'Computer Science/Technology',
        'Foreign Language',
        'Other',
      ],
    },
    {
      question: "Let's figure out some next steps! We'll reach out soon!",
      inputs: ['Your Name', 'Your Email'],
    },
  ],
  publishers: [
    {
      question: 'What grade level(s) is your target audience?',
      multiSelect: [
        'PreK',
        'K-2nd',
        '3rd-5th',
        '6th-8th',
        '9th-12th',
        'College or Graduate Studies',
        'Adult Training',
      ],
    },
    {
      question: 'What content areas? Check all that apply.',
      multiSelect: [
        'English / Language Arts',
        'Mathematics',
        'Science',
        'Social Studies',
        'Computer Science/Technology',
        'Foreign Language',
        'Other',
      ],
    },
    {
      question: 'What type of content are you looking for?',
      multiSelect: [
        'Interactives',
        'Lessons & Activities',
        'Supplemental Materials',
        'Audio + Voiceover',
        'Video, Graphics, & Animations',
        'Consultation & Ideation',
        'Virtual & Augmented Reality',
        'Accessible Technology',
        'Other',
      ],
    },
    {
      question: "Let's figure out some next steps! We'll reach out soon!",
      inputs: ['Your Name', 'Your Email'],
    },
  ],
}

export const avatars = [
  {
    name: "I'm a School",
    to: '/schools',
    questions: questions.schools,
    color: colors.lightBlue,
    video: !isSafariOrIosChrome ? schoolDirector : schoolDirectorBackup,
    info: {
      title:
        'Need to better support your teachers and students? We can help! We build custom, on-demand educational materials for any learning environment.',
      bullets: [
        'Engaging Learning Modules',
        'Digital, Print, and Blended Materials',
        'Interactive, AR/VR, Gamified & Autonomous Learning Solutions',
        'Direct Instruction, Assessments, Instructor Guides & More!',
      ],
    },
  },
  {
    name: "I'm a Publisher",
    to: '/publisher',
    questions: questions.publishers,
    color: colors.green,
    video: !isSafariOrIosChrome ? ldDirector : ldDirectorBackup,
    info: {
      title:
        "Need help developing a new product for classrooms? We have your back!! We build print and digital educational products with a teacher's touch. Whether you are updating an existing product or ideating a new one, ryco.io is here for you. We can help with print and digital products that work in any learning environment.",
    },
  },
]

export const visualOnlyAvatars = [
  {
    color: colors.pink,
    video: !isSafariOrIosChrome ? publisher : publisherBackup,
  },
  {
    color: colors.orange,
    video: !isSafariOrIosChrome ? educator : educatorBackup,
  },
]
