import { up } from 'styled-breakpoints'
import styled from 'styled-components'

const getAbsoultePosition = (
  position?: number,
  isTabletOrBelow = false,
  isTop = false
) => {
  if (!isTop) return position || position === 0 ? position + 'px' : 'auto'

  return position || position === 0
    ? position - (isTabletOrBelow ? 0 : 100) + 'px'
    : 'auto'
}

export const StyleWrapper = styled.div`
  position: absolute;
  top: -97px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;

  ${up('lg')} {
    z-index: 0;
  }
`

export const Circle = styled.div<{
  size: number
  right?: number
  top?: number
  left?: number
  bottom?: number
  isTabletOrBelow?: boolean
  asSquare?: boolean
  angle?: number
}>`
  position: absolute;
  border-radius: ${({ asSquare }) => !asSquare && '50%'};
  background-color: ${({ color }) => color};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  right: ${({ right }) => getAbsoultePosition(right)};
  top: ${({ top, isTabletOrBelow }) =>
    getAbsoultePosition(top, isTabletOrBelow, true)};
  left: ${({ left }) => getAbsoultePosition(left)};
  bottom: ${({ bottom }) => getAbsoultePosition(bottom)};
  transform: ${({ angle }) => angle && `rotate(${angle}deg)`};
`
