import * as React from 'react'

import ArrowIcon from './ArrowIcon'
import {
  Wrapper,
  Header,
  ByLine,
  Quote,
  IconButton,
  ButtonWrapper,
  MobileButtonWrapper,
  CirclesWrapper,
  Circle,
} from './styled-components'
import { testimonials } from './testimonialData'

const Testimonials = () => {
  const [currentTestimonial, setCurrentTestimonial] = React.useState(0)

  const changeTestimonial = (direction: 'next' | 'prev') => {
    if (direction === 'next') {
      setCurrentTestimonial((currentTestimonial + 1) % testimonials.length)
    } else {
      setCurrentTestimonial(
        currentTestimonial === 0
          ? testimonials.length - 1
          : currentTestimonial - 1
      )
    }
  }

  const activeTestimonial = testimonials[currentTestimonial]

  return (
    <Wrapper>
      <ButtonWrapper>
        <IconButton
          onClick={() => changeTestimonial('prev')}
          aria-label="previous testimonial"
        >
          <ArrowIcon />
        </IconButton>
        <IconButton
          onClick={() => changeTestimonial('next')}
          aria-label="next testimonial"
        >
          <ArrowIcon />
        </IconButton>
      </ButtonWrapper>
      <Header>{activeTestimonial.author}</Header>
      {activeTestimonial?.company && (
        <ByLine>@ {activeTestimonial.company}</ByLine>
      )}
      {activeTestimonial?.role && <ByLine>-{activeTestimonial.role}</ByLine>}
      <Quote>“{activeTestimonial.quote}”</Quote>
      <MobileButtonWrapper>
        <IconButton onClick={() => changeTestimonial('prev')}>
          <ArrowIcon />
        </IconButton>
        <IconButton onClick={() => changeTestimonial('next')}>
          <ArrowIcon />
        </IconButton>
      </MobileButtonWrapper>
      <CirclesWrapper>
        {testimonials.map((_testimonial, idx) => (
          <Circle key={idx} active={idx === currentTestimonial} />
        ))}
      </CirclesWrapper>
    </Wrapper>
  )
}

export default Testimonials
