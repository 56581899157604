export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  pink: '#C74ABF',
  hotPink: '#F019B4',
  magenta: '#F01966',
  rycoPurple: '#7405CC',
  darkPurple: '#5A02A0',
  lightPurple: '#C205FF',
  yellow: '#F0CE19',
  orange: '#F06619',
  burntOrange: '#F04D19',
  green: '#27CE7E',
  lightBlue: '#19BCF0',
  blue: '#0596FF',
}
