import * as React from 'react'
import { toast } from 'react-hot-toast'
import { useLocation } from 'react-router-dom'

import Facebook from 'assets/images/socialIcons/Facebook.png'
import Instagram from 'assets/images/socialIcons/Instagram.png'
import LinkedIn from 'assets/images/socialIcons/LinkedIn.png'
import Twitter from 'assets/images/socialIcons/Twitter.png'
import { linkedIn, facebook, instagram, twitter, blog } from 'constants/links'
import { Desktop, TabletAndBelow, useBreakpoints } from 'styles'

import {
  Wrapper,
  LogoLink,
  LogoSocialWrapper,
  SocialLinkIcon,
  SocialWrapper,
  NavWrapper,
  NavLink,
  CopyRight,
  Header,
  SubHeader,
  NewsLetterContainer,
  ExplainerText,
  EmailInput,
  SubmitButton,
  InputContainer,
  NavItem,
  BackgroundWrapper,
} from './styled-components'

const Footer = () => {
  const { isTabletOrBelow } = useBreakpoints()
  const [email, setEmail] = React.useState('')

  const year = new Date().getFullYear()

  const { pathname } = useLocation()
  const hideNewsLetter = pathname !== '/'

  const miniFooter =
    (pathname === '/privateSchools' || pathname === '/publicSchools') &&
    !isTabletOrBelow

  const onSubmit = async (e: React.MouseEvent) => {
    e.preventDefault()
    if (!email || !email.includes('@')) {
      toast.error('Please enter a valid email address!')
      return
    }

    const baseUrl = 'https://api.hsforms.com/submissions/v3/integration/submit'
    const portalId = '22800304'
    const formId = '1dde2736-42a9-4f42-ad52-3ca1ddc22dcf'

    const requestUrl = `${baseUrl}/${portalId}/${formId}`

    const body = {
      submittedAt: new Date().getTime(),
      fields: [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email,
        },
      ],
      context: {
        pageUri: window.location.href,
        pageName:
          window.location.href.includes('/') ||
          window.location.href.includes('/#')
            ? 'Landing Page'
            : window.location.href,
      },
    }
    try {
      await fetch(requestUrl, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      toast.success('Email submitted successfully!')
      setEmail('')
    } catch (error) {
      toast.error('Something went wrong, please try again later!')
    }
  }

  const newsLetter = hideNewsLetter ? null : (
    <BackgroundWrapper>
      <Header>Join Our Newsletter</Header>
      <SubHeader>Goodies sent directly to your mailbox</SubHeader>
      <InputContainer>
        <EmailInput
          placeholder="yourname@mail.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <SubmitButton onClick={onSubmit}>Join</SubmitButton>
      </InputContainer>
      <ExplainerText>We promise we won't spam you.</ExplainerText>
    </BackgroundWrapper>
  )

  return (
    <>
      <TabletAndBelow>{newsLetter}</TabletAndBelow>
      <Wrapper miniFooter={miniFooter}>
        <LogoSocialWrapper>
          <NavWrapper>
            <NavItem to={'/#services'}>services</NavItem>
            <NavLink href={blog}>blog</NavLink>
            <NavItem to={'/#about'}>about</NavItem>
          </NavWrapper>
          {!miniFooter && (
            <>
              <LogoLink to="/#top">ryco.io</LogoLink>
              <SocialWrapper>
                <SocialLinkIcon
                  src={Facebook}
                  alt="Facebook"
                  href={facebook}
                  aria-label="Facebook"
                />
                <SocialLinkIcon
                  src={Twitter}
                  alt="Twitter/X"
                  href={twitter}
                  aria-label="Twitter/X"
                />
                <SocialLinkIcon
                  src={Instagram}
                  alt="Instagram"
                  href={instagram}
                  aria-label="Instagram"
                />
                <SocialLinkIcon
                  src={LinkedIn}
                  alt="LinkedIn"
                  href={linkedIn}
                  aria-label="LinkedIn"
                />
              </SocialWrapper>
            </>
          )}
          <CopyRight>
            © {year} Copyright. All Rights Reserved. ryco.io
          </CopyRight>
        </LogoSocialWrapper>
        <Desktop>
          <NewsLetterContainer>{newsLetter}</NewsLetterContainer>
        </Desktop>
      </Wrapper>
    </>
  )
}

export default Footer
