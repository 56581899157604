import { Link as DefaultLink } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { colors } from 'styles'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 97px;
`

export const LogoLink = styled(DefaultLink)`
  font-family: 'Open Sans';
  text-decoration: none;
  color: ${colors.white};
  margin-left: 28px;
  margin-top: 30px;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%; /* 56px */
  letter-spacing: -0.4px;
  display: inline-block;
  position: absolute;
  z-index: 2;

  ${up('lg')} {
    font-size: 40px;
    margin-left: 122px;
    margin-top: 41px;
  }
`

export const Link = styled(HashLink)`
  text-decoration: none;
  text-align: right;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 17.6px */
  text-transform: lowercase;
  color: ${colors.white};
`

export const NavWrapper = styled.nav`
  margin-top: 58px;
  margin-right: 102px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 20px;
  position: absolute;
  z-index: 2;
  right: 0;
`
