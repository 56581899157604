import React from 'react'

import { ModalWrapper, ModalContent } from './styled-components'

const Modal = ({
  children,
  open,
  setOpen,
}: {
  children: React.ReactNode
  open: boolean
  setOpen: (bool: boolean) => void
}) => {
  if (!open) return null

  return (
    <ModalWrapper onClick={() => setOpen(false)}>
      <ModalContent>{children}</ModalContent>
    </ModalWrapper>
  )
}

export default Modal
