import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { colors } from 'styles'

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const ModalContent = styled.div`
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`

export const CloseButton = styled.button`
  position: absolute;
  top: -45px;
  left: 4px;
  border: none;
  font-size: 25px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: ${colors.lightPurple};
  color: ${colors.white};

  ${up('lg')} {
    top: -45px;
    left: 5px;
  }

  @media (max-width: 701px) {
    left: calc(50% - 200px);
  }

  @media (max-width: 445px) {
    top: -44px;
    left: 4px;
  }

  &:hover {
    background-color: ${colors.white};
    color: ${colors.lightPurple};
  }
`
