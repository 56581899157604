import * as React from 'react'

import joinTheTeam from 'assets/images/joinTheTeam.png'
import joinTheTeamWeb from 'assets/images/joinTheTeamWeb.png'
import { joinTheTeamLink } from 'constants/links'
import { Desktop, TabletAndBelow } from 'styles'

import { Wrapper, Header, SubHeader, Button, Image } from './styled-components'

const JoinTheTeam = () => {
  return (
    <Wrapper>
      <Header>Want to work with us?</Header>
      <SubHeader>
        We're always looking for smart, cool people to join us.
        <Desktop> See if you have what it takes.</Desktop>
      </SubHeader>
      <Button
        target={'_blank'}
        href={joinTheTeamLink}
        alt="Fill out a form to join the team"
        description="Application"
        rel="noreferer"
      >
        Join the team
      </Button>
      <TabletAndBelow>
        <Image
          src={joinTheTeam}
          alt="Three people grouped in a section as an example display of a team"
        />
      </TabletAndBelow>
      <Desktop>
        <Image
          src={joinTheTeamWeb}
          alt="Three people grouped in a section as an example display of a team"
        />
      </Desktop>
    </Wrapper>
  )
}

export default JoinTheTeam
