/*============================================
                IMPORTS / SETUP
=============================================*/
import axios from 'axios'

/*============================================
                SERVICE DEFINTION
=============================================*/
const baseURL = (process.env.REACT_APP_BASE_URL || '') + '/api/'

const ApiService = axios.create({
  headers: {
    'client-app-type': 'ryco-web',
    'client-app-version': process.env.REACT_APP_VERSION,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  baseURL,
})

export default ApiService
