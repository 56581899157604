import { useEffect } from 'react'

export const useFistAnimation = (
  leftFistRef: React.RefObject<HTMLImageElement>,
  rightFistRef: React.RefObject<HTMLImageElement>,
  containerRef: React.RefObject<HTMLDivElement>,
  setAnimationFinished: (bool: boolean) => void
) => {
  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect()
        const containerTop = containerRect.top

        if (containerTop <= 50) {
          leftFistRef.current?.classList.remove('animateOut')
          rightFistRef.current?.classList.remove('animateOut')
          leftFistRef.current?.classList.add('animateIn')
          rightFistRef.current?.classList.add('animateIn')

          setAnimationFinished(true)
        } else {
          leftFistRef.current?.classList.remove('animateIn')
          rightFistRef.current?.classList.remove('animateIn')
          leftFistRef.current?.classList.add('animateOut')
          rightFistRef.current?.classList.add('animateOut')

          setAnimationFinished(false)
        }
      }
    }

    // Add a scroll event listener to the document body
    document.body.addEventListener('scroll', handleScroll)

    // Remove the scroll event listener when the component unmounts
    return () => {
      document.body.removeEventListener('scroll', handleScroll)
    }
  }, [leftFistRef, rightFistRef, containerRef, setAnimationFinished])
}
