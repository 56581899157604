import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { colors } from 'styles/colors'

export const Header = styled.h1`
  margin: 100px 30px 0 30px;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  color: ${colors.white};
  z-index: 1;

  ${up('lg')} {
    font-size: 50px;
    margin: 111px 0px 25px 122px;
  }
`

export const SubHeader = styled.h2`
  margin: 12px 30px 0 30px;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: ${colors.white};
  z-index: 1;
  ${up('lg')} {
    font-size: 26px;
    margin: 0px 244px 0px 122px;
  }
`

export const Tagline = styled.h3`
  border-radius: 15px;
  background: ${colors.yellow};
  /* one off color to suit accesibililty standards */
  color: #d83701;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
  z-index: 1;

  transform: rotate(-2.488deg);
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 50px;
  margin: 41px auto 0 auto;

  /* fixes jagged outline in mobile safari */
  outline: 1px solid transparent;

  ${up('lg')} {
    font-size: 28px;
    width: 370px;
    height: 70px;
  }
`
