import React from 'react'

import leftFist from 'assets/images/aboutUs/leftFist.png'
import rightFist from 'assets/images/aboutUs/rightFist.png'
import { HUBSPOT_RILEY } from 'constants/links'
import { colors, Desktop, useBreakpoints } from 'styles'

import { useFistAnimation } from './hooks'
import {
  Container,
  Header,
  SubHeader,
  Button,
  LeftFist,
  RightFist,
  CustomCircle as Circle,
  animationTiming,
} from './styled-components'

const AboutUs = () => {
  const { isTabletOrBelow } = useBreakpoints()
  const leftFistRef = React.useRef<HTMLImageElement>(null)
  const rightFistRef = React.useRef<HTMLImageElement>(null)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [animationFinished, setAnimationFinished] = React.useState(false)

  const customSetAnimationFinished = (state: boolean) => {
    setTimeout(() => {
      setAnimationFinished(state)
    }, animationTiming * 1000)
  }

  useFistAnimation(
    leftFistRef,
    rightFistRef,
    containerRef,
    customSetAnimationFinished
  )

  const adjustForOffsetTop = (offset: number) => offset + 100

  return (
    <Container id="about" ref={containerRef}>
      <Header>
        Let's Bring Change{' '}
        <Desktop>
          <br />
        </Desktop>{' '}
        to Classrooms
      </Header>
      <SubHeader>
        Give your teachers time back and decrease burn-out with on-demand
        content created by experienced educators.
      </SubHeader>

      <Button href={HUBSPOT_RILEY}>Change is one click away</Button>

      <Circle
        animationFinished={animationFinished}
        size={isTabletOrBelow ? 144 : 365}
        top={adjustForOffsetTop(isTabletOrBelow ? 25 : -42)}
        left={isTabletOrBelow ? -70 : -81}
        color={isTabletOrBelow ? colors.burntOrange : colors.black}
      />
      <Circle
        animationFinished={animationFinished}
        size={isTabletOrBelow ? 32 : 51}
        top={adjustForOffsetTop(isTabletOrBelow ? 5 : 41)}
        left={isTabletOrBelow ? 50 : 285}
        color={isTabletOrBelow ? colors.burntOrange : colors.black}
      />

      <Desktop>
        <Circle
          animationFinished={animationFinished}
          size={332}
          top={adjustForOffsetTop(80)}
          right={-20}
          color={colors.yellow}
          asSquare
          angle={-14.599}
        />
        <Circle
          animationFinished={animationFinished}
          size={65}
          top={adjustForOffsetTop(-24)}
          right={118}
          color={colors.yellow}
        />
        <Circle
          animationFinished={animationFinished}
          size={276}
          top={adjustForOffsetTop(367)}
          left={193}
          color={colors.burntOrange}
        />
        <Circle
          animationFinished={animationFinished}
          size={48}
          top={adjustForOffsetTop(457)}
          left={126}
          color={colors.burntOrange}
        />
        <Circle
          animationFinished={animationFinished}
          size={56}
          top={adjustForOffsetTop(575)}
          right={49}
          color={colors.lightBlue}
        />
      </Desktop>

      <Circle
        animationFinished={animationFinished}
        size={isTabletOrBelow ? 70 : 136}
        top={adjustForOffsetTop(isTabletOrBelow ? 355 : 586)}
        color={colors.green}
      />

      <Circle
        animationFinished={animationFinished}
        size={isTabletOrBelow ? 91 : 121}
        top={adjustForOffsetTop(isTabletOrBelow ? 244 : 600)}
        left={isTabletOrBelow ? -22 : 90}
        color={colors.yellow}
        asSquare
        angle={35.161}
      />
      <Circle
        animationFinished={animationFinished}
        size={isTabletOrBelow ? 178 : 282}
        top={adjustForOffsetTop(isTabletOrBelow ? 191 : 643)}
        right={isTabletOrBelow ? -50 : -77}
        color={colors.lightBlue}
      />
      <LeftFist alt="fist left" ref={leftFistRef} src={leftFist} />
      <RightFist alt="fist right" ref={rightFistRef} src={rightFist} />
    </Container>
  )
}

export default AboutUs
