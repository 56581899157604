import {
  Wrapper as QuestionsWrapper,
  Button as DefaultButton,
  EmailInput as DefaultInput,
} from 'components/Testimonials/styled-components'
import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import { colors } from 'styles'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding-top: 60px;

  ${up('lg')} {
    flex-direction: row;
    padding-top: 32px;
    padding-bottom: 180px;
    max-height: 420px;
    overflow: visible;
  }
`

export const AvatarWrapper = styled.div`
  ${up('lg')} {
    margin-right: 20px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  width: calc(100% - 48px);
  color: ${colors.white};
  margin: 24px 0px;
  overflow: hidden;

  ${up('lg')} {
    margin: 145px 120px 0 120px;
    width: calc(100% - 120px);
    padding: 0px;
  }
`

export const Header = styled.h1`
  margin: 0;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 120%;
  max-width: 50%;

  ${up('lg')} {
    font-size: 55px;
  }
`

export const SubHeader = styled.h2`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;

  ${up('lg')} {
    font-size: 22px;
  }
`

export const QuestionCount = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${colors.rycoPurple};
  margin-top: 22px;
  margin-bottom: 12px;
`

export const QuestionsSection = styled(QuestionsWrapper)`
  padding: 0px;
  min-width: 100%;
  min-height: 200px;
  justify-content: flex-start;

  ${up('lg')} {
    min-width: 500px;
    max-width: 500px;
    border-radius: 40px;
    margin-bottom: 50px;
    margin-right: 0px;
  }
`

export const QuestionLabel = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin: 0 45px;
  margin-bottom: 26px;

  color: ${colors.black};
`

export const Option = styled(DefaultButton)<{ selected: boolean }>`
  color: ${colors.black};
  background-color: ${colors.white};
  margin-bottom: 20px;

  ${({ selected }) =>
    selected &&
    css`
      background: ${colors.rycoPurple};
      color: ${colors.white};
      border-color: ${colors.rycoPurple};
    `}

  ${up('lg')} {
    margin-top: 0px;
    width: 350px;
    height: 56px;
  }
`

export const NextButton = styled(DefaultButton)`
  border: none;
  margin-bottom: 45px;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-self: center;
  color: ${colors.white};
  background: radial-gradient(
      83.94% 83.94% at 26.39% 20.83%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    ),
    #000;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}

  ${up('lg')} {
    margin-top: 0px;
    width: 350px;
    height: 56px;
  }
`

export const Input = styled(DefaultInput)`
  ${up('lg')} {
    margin-top: 0px;
    width: 346px;
    height: 52px;
  }
`

export const Loader = styled.div`
  border: 5px solid ${colors.white};
  border-top: 5px solid ${colors.rycoPurple};
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const InfoBulletsWrapper = styled.ul`
  text-align: left;
  color: ${colors.black};
  ${up('lg')} {
    margin-top: 0px;
    margin-bottom: 25px;
    width: 350px;
  }
`
