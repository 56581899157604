import * as React from 'react'
import { toast } from 'react-hot-toast'
import { useTrackingCode } from 'react-hubspot-tracking-code-hook'

import arrowDown from 'assets/images/icons/arrowDown.png'
import {
  ExplainerText,
  EmailInput,
  InputContainer,
  SubmitButton,
} from 'components/Footer/styled-components'
import {
  Wrapper,
  Header,
  TextWrapper,
  SubHeader,
  Icon,
} from 'pages/Done/styled-components'
import { Desktop, TabletAndBelow } from 'styles'

const NewsLetter = () => {
  const { setPathPageView } = useTrackingCode()

  React.useEffect(() => {
    setPathPageView(window.location.pathname)
  }, [setPathPageView])

  const [email, setEmail] = React.useState('')

  const onSubmit = async (e: React.MouseEvent) => {
    e.preventDefault()
    if (!email || !email.includes('@')) {
      toast.error('Please enter a valid email address!')
      return
    }

    const baseUrl = 'https://api.hsforms.com/submissions/v3/integration/submit'
    const portalId = '22800304'
    const formId = '1dde2736-42a9-4f42-ad52-3ca1ddc22dcf'

    const requestUrl = `${baseUrl}/${portalId}/${formId}`

    const body = {
      submittedAt: new Date().getTime(),
      fields: [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email,
        },
      ],
      context: {
        pageUri: window.location.href,
        pageName: window.location.pathname,
      },
    }
    try {
      await fetch(requestUrl, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      toast.success('Email submitted successfully!')
      window.location.href = '/'
    } catch (error) {
      toast.error('Something went wrong, please try again later!')
    }
  }

  const content = (
    <>
      <TextWrapper>
        <Header>Join Our Newsletter</Header>

        <SubHeader>Goodies sent directly to your mailbox</SubHeader>
      </TextWrapper>
      <Icon alt="arrow down" src={arrowDown} />

      <InputContainer>
        <EmailInput
          placeholder="name@mail.com"
          style={{ width: '75%' }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <SubmitButton onClick={onSubmit}>Join</SubmitButton>
      </InputContainer>
      <ExplainerText>We promise we won't spam you.</ExplainerText>
    </>
  )

  return (
    <>
      <Desktop>
        <Wrapper style={{ height: 'calc(100vh - 97px - 418px - 160px)' }}>
          {content}
        </Wrapper>
      </Desktop>
      <TabletAndBelow>
        <Wrapper style={{ height: 'calc(100vh - 97px - 144px - 60px)' }}>
          {content}
        </Wrapper>
      </TabletAndBelow>
    </>
  )
}

export default NewsLetter
