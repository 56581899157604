import React, { useState } from 'react'

import { Button } from './styled-components'

const ScrollButton = () => {
  const [showScroll, setShowScroll] = useState(false)

  const element = document.body

  React.useEffect(() => {
    const checkScrollTop = () => {
      //if the user scrolls down 400px, show the button
      if (!showScroll && element && element.scrollTop > 400) {
        setShowScroll(true)
      } else if (showScroll && element && element.scrollTop <= 400) {
        //if the user scrolls back up to the top, hide the button
        setShowScroll(false)
      }
    }

    element?.addEventListener('scroll', checkScrollTop)
    return () => {
      element?.removeEventListener('scroll', checkScrollTop)
    }
  }, [showScroll, element])

  const scrollTop = () => element?.scrollTo({ top: 0, behavior: 'smooth' })
  return (
    <Button onClick={scrollTop} visible={showScroll}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <circle
          cx="20"
          cy="20"
          r="19"
          fill="white"
          stroke="#7405CC"
          strokeWidth="2"
        />
        <path d="M10 24L19.3827 16L29 24" stroke="#7405CC" strokeWidth="2" />
      </svg>
    </Button>
  )
}

export default ScrollButton
