import * as React from 'react'
import { toast } from 'react-hot-toast'
import { useTrackingCode } from 'react-hubspot-tracking-code-hook'
import { useNavigate } from 'react-router-dom'

import { StandAloneAvatar } from 'components/Avatars'
import { avatars } from 'constants/avatars'
import { useCreateSubmission } from 'hooks/resources/questions'
import { Desktop, TabletAndBelow } from 'styles'

import {
  Wrapper,
  AvatarWrapper,
  Header,
  TextWrapper,
  SubHeader,
  QuestionCount,
  QuestionsSection,
  QuestionLabel,
  Option,
  NextButton,
  Input,
  Loader,
  InfoBulletsWrapper,
} from './styled-components'

type questionType = {
  question: string
  options?: string[]
  inputs?: string[]
  multiSelect?: string[]
}

const Questions = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
    document.body.scrollTo(0, 0)
  }, [])

  const { setPathPageView } = useTrackingCode()

  React.useEffect(() => {
    setPathPageView(window.location.pathname)
  }, [setPathPageView])

  const navigate = useNavigate()

  const [infoPageViewed, setInfoPageViewed] = React.useState(false)
  const [currentQuestion, setCurrentQuestion] = React.useState(1)
  const [currentSelection, setCurrentSelection] = React.useState('')
  const [currentMultiSelect, setCurrentMultiSelect] = React.useState<
    Array<String>
  >([])
  const [currentShortAnswer, setCurrentShortAnswer] = React.useState<{
    [key: number]: string[] | string
  }>({})
  const [loading, setLoading] = React.useState(false)

  const [allQuestionsAndAnswers, setAllQuestionsAndAnswers] = React.useState({})

  const { color, video, name, questions, info } = avatars.filter(
    (avatar) => avatar.to === window.location.pathname
  )[0]

  const totalQuestions = questions.length
  const activeQuestion: questionType = questions[currentQuestion - 1]
  const isMultiChoice = activeQuestion.options
  const isShortAnswer = activeQuestion.inputs
  const isMultiSelect = activeQuestion.multiSelect

  const { mutate: createSubmission } = useCreateSubmission({
    onSuccess: () => {
      const usersName = currentShortAnswer[0]
      setCurrentSelection('')
      setCurrentMultiSelect([])
      setCurrentShortAnswer({})
      setCurrentQuestion(1)
      setAllQuestionsAndAnswers({})
      setLoading(false)
      toast.success('Answers submitted successfully!')
      const b64String = btoa(
        `${window.location.pathname.replace('/', '')}/${usersName}`
      )
      navigate(`/done/${b64String}`)
    },
    onError: () => {
      setLoading(false)
      toast.error('Something went wrong, please try again later!')
    },
  })

  const handleMultSelect = (option: string) => {
    if (!currentMultiSelect.includes(option)) {
      setCurrentMultiSelect([...currentMultiSelect, option])
    } else {
      const copy = currentMultiSelect.filter((o) => o !== option)
      setCurrentMultiSelect([...copy])
    }
  }

  const handleNextButtonPress = () => {
    if (
      currentQuestion - 1 < totalQuestions &&
      (currentSelection ||
        currentMultiSelect?.length > 0 ||
        currentShortAnswer?.[0]?.length)
    ) {
      setAllQuestionsAndAnswers({
        ...allQuestionsAndAnswers,
        [activeQuestion.question]: isMultiChoice
          ? currentSelection
          : isShortAnswer
          ? currentShortAnswer
          : currentMultiSelect,
      })

      setCurrentSelection('')
      setCurrentMultiSelect([])
      setCurrentShortAnswer({})
      setCurrentQuestion(currentQuestion + 1)
    } else {
      toast.error('Response is required')
    }
  }

  const handleSubmitButtonPress = () => {
    if (
      currentShortAnswer?.[0]?.length &&
      currentShortAnswer?.[1]?.length &&
      currentShortAnswer?.[1]?.includes('@')
    ) {
      setLoading(true)
      setAllQuestionsAndAnswers({
        ...allQuestionsAndAnswers,
        [activeQuestion.question]: currentShortAnswer,
      })

      createSubmission({
        data: {
          ...allQuestionsAndAnswers,
          [activeQuestion.question]: currentShortAnswer,
        },
        type: name,
        usersName: currentShortAnswer[0] as string,
        usersEmail: currentShortAnswer[1] as string,
      })
    } else {
      toast.error('Name and a valid email are required')
    }
  }

  return (
    <>
      <Desktop>
        <TextWrapper>
          <Header>Hi {name}!</Header>
          <SubHeader>LET'S GET TO KNOW YOU:</SubHeader>
        </TextWrapper>
      </Desktop>
      <Wrapper>
        <AvatarWrapper>
          <StandAloneAvatar color={color} video={video} size={[242, 420]} />
        </AvatarWrapper>

        <TabletAndBelow>
          <TextWrapper>
            <Header>Hi {name}!</Header>
            <SubHeader>LET'S GET TO KNOW YOU:</SubHeader>
          </TextWrapper>
        </TabletAndBelow>

        <QuestionsSection>
          {infoPageViewed ? (
            <>
              <QuestionCount>
                {currentQuestion} of {totalQuestions}
              </QuestionCount>
              <QuestionLabel>
                {currentQuestion}: {activeQuestion.question}
              </QuestionLabel>
              {isMultiChoice &&
                activeQuestion?.options?.map((option) => (
                  <Option
                    selected={currentSelection === option}
                    onClick={() => setCurrentSelection(option)}
                    key={option}
                  >
                    {option}
                  </Option>
                ))}
              {isShortAnswer &&
                activeQuestion?.inputs?.map((input, index) => (
                  <Input
                    key={input}
                    placeholder={input}
                    value={currentShortAnswer[index]}
                    onChange={(e) =>
                      setCurrentShortAnswer({
                        ...currentShortAnswer,
                        [index]: e.target.value,
                      })
                    }
                  />
                ))}
              {isMultiSelect &&
                activeQuestion?.multiSelect?.map((option) => (
                  <Option
                    selected={currentMultiSelect.includes(option)}
                    onClick={() => handleMultSelect(option)}
                    key={option}
                  >
                    {option}
                  </Option>
                ))}
            </>
          ) : (
            <>
              <QuestionCount />
              <QuestionLabel>{info.title}</QuestionLabel>
              {info?.bullets && (
                <InfoBulletsWrapper>
                  {info.bullets.map((bullet) => (
                    <li key={bullet}>{bullet}</li>
                  ))}
                </InfoBulletsWrapper>
              )}
            </>
          )}
          <NextButton
            disabled={loading}
            onClick={
              !infoPageViewed
                ? () => setInfoPageViewed(true)
                : currentQuestion === totalQuestions
                ? handleSubmitButtonPress
                : handleNextButtonPress
            }
          >
            {loading ? (
              <Loader />
            ) : !infoPageViewed ? (
              'Continue'
            ) : currentQuestion === totalQuestions ? (
              'Submit'
            ) : (
              'Next'
            )}
          </NextButton>
        </QuestionsSection>
      </Wrapper>
    </>
  )
}

export default Questions
