import { PropsWithChildren } from 'react'

import { isNull } from 'lodash'
import { down, only, up } from 'styled-breakpoints'
import { useBreakpoint } from 'styled-breakpoints/react-styled'

export const breakpoints = {
  sm: '360px',
  md: '768px',
  lg: '1024px',
}

const _window = typeof window !== 'undefined' ? window : null
const _windowWidth = _window ? _window.innerWidth : 0
let lastKnownBreakpoints = {
  isMobile: _windowWidth < parseInt(breakpoints.md),
  isTablet:
    _windowWidth >= parseInt(breakpoints.md) &&
    _windowWidth < parseInt(breakpoints.lg),
  isDesktop: _windowWidth >= parseInt(breakpoints.lg),
  isNonMobile: _windowWidth >= parseInt(breakpoints.md),
  isTabletOrBelow: _windowWidth <= parseInt(breakpoints.md),
}

export const useBreakpoints = () => {
  const isMobile = useBreakpoint(down('sm'))
  const isTablet = useBreakpoint(only('md')) || false
  const isDesktop = useBreakpoint(up('lg')) || false
  const isNonMobile = !isMobile || isTablet || isDesktop
  const isTabletOrBelow = isDesktop === false

  // the useBreakpoint hook returns null values after page navigation within the app
  // we cache the last known breakpoints to avoid jank in components that rely on this hook
  if (isNull(isMobile)) return lastKnownBreakpoints

  lastKnownBreakpoints = {
    isMobile,
    isTablet,
    isDesktop,
    isNonMobile,
    isTabletOrBelow,
  }

  return {
    isMobile,
    isTablet,
    isDesktop,
    isNonMobile,
    isTabletOrBelow,
  }
}

export const Mobile: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { isMobile } = useBreakpoints()
  return isMobile ? <>{children}</> : null
}

export const Tablet: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { isTablet } = useBreakpoints()
  return isTablet ? <>{children}</> : null
}

export const TabletAndBelow: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { isTabletOrBelow } = useBreakpoints()
  return isTabletOrBelow ? <>{children}</> : null
}

export const Desktop: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { isDesktop } = useBreakpoints()
  return isDesktop ? <>{children}</> : null
}

export const NonMobile: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { isNonMobile } = useBreakpoints()
  return isNonMobile ? <>{children}</> : null
}
