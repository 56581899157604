import React from 'react'

import overlay from 'assets/images/k12Publishers/overlay.png'
import { StandAloneAvatar } from 'components/Avatars'
import { visualOnlyAvatars } from 'constants/avatars'
import { HUBSPOT_RILEY } from 'constants/links'
import { TabletAndBelow, Desktop } from 'styles'

import {
  Container,
  Tagline,
  SubContainer,
  Content,
  Button,
  BulletPoint,
  AvatarWrapperLarge,
  AvatarWrapperSmall,
  ImageWrapper,
  ImageOverlay,
} from './styled-components'

const LDTeams = () => {
  return (
    <Container>
      <SubContainer>
        <Tagline>L&D Teams Welcome</Tagline>
        <Content>
          We can help you build custom courses and training that help your team:
          <br />
          <br />
          <ul style={{ margin: 0 }}>
            <BulletPoint>Increase employee support and retention</BulletPoint>
            <BulletPoint>Represent your unique company</BulletPoint>
            <BulletPoint>
              Reduce time spent on onboarding and new employee training
            </BulletPoint>
            <BulletPoint>
              Foster inclusive learning for all employees
            </BulletPoint>
          </ul>
        </Content>
        <Desktop>
          <Button href={HUBSPOT_RILEY}>Let's collaborate</Button>
        </Desktop>
      </SubContainer>
      <SubContainer>
        <ImageWrapper>
          <AvatarWrapperLarge>
            <StandAloneAvatar
              size={[220, 320]}
              color={visualOnlyAvatars[0].color}
              video={visualOnlyAvatars[0].video}
            />
          </AvatarWrapperLarge>
          <AvatarWrapperSmall>
            <StandAloneAvatar
              size={[172, 252]}
              color={visualOnlyAvatars[1].color}
              video={visualOnlyAvatars[1].video}
            />
          </AvatarWrapperSmall>
          <ImageOverlay
            src={overlay}
            alt="visual flair, including a yellow cirlce, a chart, and a molecule"
          />
        </ImageWrapper>
      </SubContainer>
      <TabletAndBelow>
        <Button href={HUBSPOT_RILEY}>Let's collaborate</Button>
      </TabletAndBelow>
    </Container>
  )
}

export default LDTeams
