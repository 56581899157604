import React from 'react'

import { SubHeader, Header, Tagline } from 'pages/Home/styled-components'
import { colors } from 'styles'

import data from './data'

const ResourceHub = () => {
  const [sectionOpen, setSectionOpen] = React.useState<number | null>(null)
  return (
    <div
      style={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 97px)',
        alignItems: 'center',
      }}
    >
      <Header
        style={{
          alignSelf: 'flex-start',
          color: colors.black,
        }}
      >
        Resource Hub
      </Header>
      <SubHeader
        style={{
          color: colors.black,
          alignSelf: 'flex-start',
          marginBottom: '20px',
        }}
      >
        Toggle the sections below to view video tutorials and resources.
      </SubHeader>
      <Tagline>Platform Video Tutorials</Tagline>
      <div style={{ height: '75px' }} />
      {data.map((section, idx) => (
        <div
          key={idx}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '20px',
            border: `2px solid ${colors.rycoPurple}`,
            borderRadius: '15px',
            maxWidth: '85%',
            minWidth: '85%',
          }}
        >
          <button
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              width: '100%',
            }}
            onClick={() => setSectionOpen(sectionOpen === idx ? null : idx)}
          >
            <h1
              style={{
                marginTop: '20px',
                marginBottom: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingLeft: '20px',
                paddingRight: '20px',
              }}
            >
              {section.title}
              <div
                style={{
                  width: '30px',
                  height: '30px',
                }}
              >
                {sectionOpen !== idx ? (
                  <svg
                    width="23"
                    height="26"
                    viewBox="0 0 23 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23 13L0.5 25.9904L0.5 0.00961876L23 13Z"
                      fill="#7405CC"
                    />
                  </svg>
                ) : (
                  <svg
                    width="26"
                    height="23"
                    viewBox="0 0 26 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 23L0.00961876 0.5L25.9904 0.5L13 23Z"
                      fill="#7405CC"
                    />
                  </svg>
                )}
              </div>
            </h1>
          </button>

          {sectionOpen === idx && (
            <>
              <iframe
                style={{
                  marginBottom: '20px',
                  border: 'none',
                  borderRadius: '15px',
                  maxWidth: '85%',
                }}
                title={section.title}
                src={section.video}
                width="560"
                height="315"
                allow="autoplay"
              />
              {section.details.map((detail, idx) => (
                <div
                  key={idx}
                  style={{
                    marginBottom: '20px',
                    width: '85%',
                  }}
                >
                  <h3
                    style={{
                      color: colors.rycoPurple,
                    }}
                  >
                    {idx + 1}. {detail.title}
                  </h3>
                  <p style={{ margin: 0 }}>{detail.description}</p>
                </div>
              ))}
              {section.additional && (
                <div
                  style={{
                    width: '85%',
                    marginBottom: '20px',
                  }}
                >
                  {section.additional.map((add, idx) => (
                    <div key={idx}>
                      <h3
                        style={{
                          color: colors.rycoPurple,
                          marginTop: '40px',
                        }}
                      >
                        {add.title}
                      </h3>
                      <ul>
                        {add.data.map((data, idx) => (
                          <li key={idx}>
                            {data.split(':').length > 1 ? (
                              <>
                                <strong
                                  style={{
                                    color: colors.rycoPurple,
                                  }}
                                >
                                  {data.split(':')[0]}:
                                </strong>
                                {data.split(':')[1]}
                              </>
                            ) : (
                              data
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  )
}

export default ResourceHub
