import * as React from 'react'
import { useTrackingCode } from 'react-hubspot-tracking-code-hook'

const Error = () => {
  const { setPathPageView } = useTrackingCode()

  React.useEffect(() => {
    setPathPageView('/error')
  }, [setPathPageView])

  window.location.href = '/'
  return (
    <div>
      <h1>404</h1>
      <p>Page not found</p>
    </div>
  )
}

export default Error
