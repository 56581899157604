//TODO: lift these to common components
import { Tagline as DefaultTagline } from 'pages/Home/styled-components'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { colors } from 'styles/colors'

import { Button as DefaultButton } from '../ValueProp/styled-components'

export const Container = styled.div`
  display: flex;
  background-color: ${colors.white};
  position: relative;
  overflow: hidden;
  margin: 60px auto 0 auto;
  flex-direction: column;

  ${up('lg')} {
    margin-top: -190px;
    padding-top: 190px;
    padding-bottom: 125px;
    flex-direction: row;
  }
`

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  :first-child {
    padding: 40px 24px 0 24px;
  }

  ${up('lg')} {
    padding: 40px 24px 0 24px;
    :first-child {
      margin-left: auto;
      padding-right: 124px;
      max-width: 50%;
      overflow: hidden;
    }
    :last-child {
      margin-right: auto;
    }
  }
`

export const Content = styled.span`
  color: ${colors.black};
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 55px 0 0 0;
  max-width: 463px;

  ${up('lg')} {
    font-size: 18px;
    margin: 55px 0 50px 0;
  }
`

export const Tagline = styled(DefaultTagline)`
  width: 330px;
  height: 50px;
  margin: 0;

  ${up('lg')} {
    align-self: flex-start;
    width: 385px;
    height: 70px;
  }
`

export const Button = styled(DefaultButton)`
  margin: 35px 0 65px 0;
  ${up('lg')} {
    margin: 0;
    align-self: flex-start;
  }
`

export const BulletPoint = styled.li``

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
`

export const AvatarWrapperLarge = styled.div`
  margin-top: 18px;
  margin-right: -42px;

  ${up('lg')} {
    margin-top: 16px;
    margin-right: -52px;
  }
`
export const AvatarWrapperSmall = styled.div``

export const ImageOverlay = styled.img`
  width: 588px;
  z-index: 5;
  position: absolute;
  display: none;
  top: -15px;
  left: 15px;
  ${up('lg')} {
    display: block;
  }
`
