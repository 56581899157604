import { HashLink } from 'react-router-hash-link'

import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import { colors } from 'styles/colors'

export const Wrapper = styled.div<{ miniFooter?: boolean }>`
  display: flex;
  width: 100%;
  background-color: ${colors.rycoPurple};
  border-top: 1px solid ${colors.white};

  ${({ miniFooter }) =>
    miniFooter &&
    css`
      background-color: ${colors.lightPurple};
    `}

  ${up('lg')} {
    padding: 70px 120px 34px 120px;
    width: calc(100% - 240px);
    border-top: none;
  }
`

export const LogoSocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 18px 24px;
  width: 50%;

  ${up('lg')} {
    margin: 0;
  }
`

export const LogoLink = styled(HashLink)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${colors.white};
  text-decoration: none;
  width: fit-content;

  ${up('lg')} {
    font-size: 40px;
  }
`

export const SocialWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 17px;
  overflow: visible;

  ${up('lg')} {
    margin-top: 35px;
  }
`

export const SocialLinkIcon = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer',
})<{ src: string; alt: string }>`
  text-decoration: none;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 25px;
  height: 25px;

  &:not(:last-child) {
    margin-right: 13px;
  }

  ${up('lg')} {
    width: 34px;
    height: 34px;
    &:not(:last-child) {
      margin-right: 18px;
    }
  }
`

export const NavWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;

  ${up('lg')} {
    margin: 0 0 35px 0;
  }
`

export const NavLink = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer',
})`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-transform: lowercase;
  color: ${colors.white};
  text-decoration: none;

  display: none;

  ${up('lg')} {
    display: block;
    font-size: 16px;
    line-height: 56px;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`

export const NavItem = styled(HashLink)`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-transform: lowercase;
  color: ${colors.white};
  text-decoration: none;

  display: none;

  ${up('lg')} {
    display: block;
    font-size: 16px;
    line-height: 56px;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`

export const CopyRight = styled.p`
  margin: 0;
  margin-top: 17px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: ${colors.white};
  white-space: nowrap;

  ${up('lg')} {
    margin-top: 35px;
    margin-bottom: 45px;
    font-size: 15px;
  }
`

export const Header = styled.h1`
  margin: 0;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 115%; /* 46px */
  color: ${colors.white};
  text-align: center;
  padding-top: 50px;

  ${up('lg')} {
    font-size: 40px;
    text-align: left;
    margin: 0;
    padding-bottom: 8px;
    padding-top: 0;
  }
`

export const SubHeader = styled.h2`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  color: ${colors.white};
  margin-bottom: 0px;
  text-align: center;

  ${up('lg')} {
    font-size: 18px;
    text-align: left;
    margin: 0;
  }
`

export const NewsLetterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`

export const ExplainerText = styled.p`
  color: ${colors.white};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  margin: 0;
  text-align: center;
  padding-bottom: 45px;

  ${up('lg')} {
    text-align: left;
    font-size: 12px;
    padding-bottom: 0;
  }
`

export const EmailInput = styled.input`
  padding: 17px 50px 15px 50px;
  border-radius: 50px;

  background-color: ${colors.white};
  border: none;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  ::placeholder {
    overflow: visible;
  }

  align-self: center;
  width: calc(75% - 100px);

  ${up('lg')} {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
    width: auto;
  }
`

export const SubmitButton = styled.button`
  border-radius: 109px;
  background: radial-gradient(
      83.94% 83.94% at 26.39% 20.83%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    ),
    #000;
  color: ${colors.white};

  height: 59px;
  align-items: flex-start;
  gap: 10px;
  border: none;

  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  width: 75%;
  align-self: center;
  justify-content: center;
  margin-top: 20px;

  ${up('lg')} {
    padding: 16px 150px;

    display: inline;
    padding: 0 50px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-top: 0;
  }
`

export const InputContainer = styled.div`
  margin: 44px 0 20px 0;
  display: flex;
  flex-direction: column;

  ${up('lg')} {
    flex-direction: row;
  }
`

export const BackgroundWrapper = styled.div`
  background-color: ${colors.rycoPurple};
`
