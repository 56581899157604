import { useBreakpoints } from 'styles'

const ArrowIcon = () => {
  const { isTabletOrBelow } = useBreakpoints()

  return isTabletOrBelow ? (
    <svg
      width="10"
      height="21"
      viewBox="0 0 10 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.64444 20L2 10.6173L8.64444 1"
        stroke="#5A02A0"
        strokeWidth="2"
      />
    </svg>
  ) : (
    <svg
      width="30"
      height="47"
      viewBox="0 0 30 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1053_42)">
        <path
          d="M30.0694 46H23.5C11.0736 46 1 35.9264 1 23.5C1 11.0736 11.0736 1 23.5 1L30.0694 1"
          stroke="#5A02A0"
          strokeWidth="2"
        />
        <path
          d="M20.9335 33L14.2891 23.6173L20.9335 14"
          stroke="#5A02A0"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1053_42">
          <rect
            width="47"
            height="30"
            fill="white"
            transform="translate(0 47) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArrowIcon
