import { H } from 'highlight.run'

import ApiService from '../ApiService'

export default class QuestionsService {
  static async createSubmission({
    data,
    type,
    usersName,
    usersEmail,
  }: {
    data: any
    type: string
    usersName: string
    usersEmail: string
  }) {
    H.identify(usersEmail, {
      email: usersEmail,
      name: usersName,
    })
    const response = await ApiService.request({
      url: `/submit-questions`,
      method: 'POST',
      data: {
        data,
        type,
        usersName,
        usersEmail,
      },
    })

    return response.data
  }
}
