import { useEffect, useCallback } from 'react'

import { posts as wixPostsModule } from '@wix/blog'
import { useWixModules } from '@wix/sdk-react'
import { useLocalStorage } from 'utils'

const CLIENT_ID = process.env.REACT_APP_WIX_CLIENT_ID

export const tagIds = {
  schools: 'a791f755-e3aa-4da9-85cb-c3a986a26423',
  publishers: '8bd6341e-dd30-4d49-8238-9c3be7b60c0f',
}

export const useBlog = (opitons: { limit: number }) => {
  const { limit } = opitons
  const date = new Date().toLocaleDateString('en-US')

  const [posts, setPosts] = useLocalStorage<wixPostsModule.Post[]>(
    `blog-posts`,
    []
  )
  const [lastFetch, setLastFetch] = useLocalStorage<string>(
    'last-blog-fetch',
    ''
  )
  const [lastOptions, setLastOptions] = useLocalStorage<{ limit: number }>(
    'last-blog-options',
    opitons
  )

  const { listPosts } = useWixModules(wixPostsModule)

  const optionsUnchanged =
    JSON.stringify(opitons) === JSON.stringify(lastOptions)

  const fetchPosts = async () => {
    if (
      (posts.length > 0 && lastFetch === date && optionsUnchanged) ||
      !CLIENT_ID
    )
      return

    const response = await listPosts({
      paging: { limit },
      fieldsets: ['URL', 'CONTENT'] as wixPostsModule.PostFieldField[],
      ...opitons,
    })
    setLastFetch(date)
    setLastOptions(opitons)
    setPosts(response.posts)
  }

  const getRequestedPosts = useCallback(fetchPosts, [
    setPosts,
    posts.length,
    lastFetch,
    setLastFetch,
    date,
    limit,
    opitons,
    setLastOptions,
    optionsUnchanged,
    listPosts,
  ])

  useEffect(() => {
    getRequestedPosts()
  }, [getRequestedPosts])

  return { posts }
}
