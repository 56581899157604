import { up } from 'styled-breakpoints'
import styled from 'styled-components'

export const Button = styled.button<{ visible: boolean }>`
  position: fixed;
  right: 14px;
  bottom: 80px;
  z-index: 10;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  display: ${({ visible }) => !visible && 'none'};

  ${up('lg')} {
    right: 34px;
  }
`
