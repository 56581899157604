//TODO: lift these to common components
import {
  Tagline as DefaultTagline,
  Header as DefaultHeader,
} from 'pages/Home/styled-components'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { colors } from 'styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  position: relative;
  overflow: hidden;
  padding-bottom: 165px;
`

export const Header = styled(DefaultHeader)`
  color: ${colors.black};
  text-align: center;
  margin: 0;
  margin-top: 100px;
`

export const Tagline = styled(DefaultTagline).attrs({
  as: 'h2',
})`
  transform: rotate(2.488deg);

  width: 300px;
  height: 80px;

  ${up('lg')} {
    width: 520px;
    height: 70px;
  }
`

export const Image = styled.img`
  width: 170px;
  height: 230px;
`

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 50px;
  flex-wrap: wrap;

  ${up('lg')} {
    flex-wrap: nowrap;
  }
`

export const ImageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  :first-child {
    > p {
      max-width: 165px;
    }
  }
`

export const ImageLabel = styled.p`
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  color: ${colors.black};
  max-width: 180px;
  margin-top: 20px;
`

export const Button = styled.a.attrs({
  target: '_blank',
})`
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-self: center;
  color: ${colors.white};
  background: radial-gradient(
      83.94% 83.94% at 26.39% 20.83%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    ),
    #000;
  border: none;
  border-radius: 109px;
  width: 75%;
  height: 56px;
  text-decoration: none;
  margin-top: 60px;

  ${up('lg')} {
    height: 60px;
    font-size: 18px;
    padding: 0 30px;
    width: fit-content;
  }
`
