import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useTrackingCode } from 'react-hubspot-tracking-code-hook'

import arrowDown from 'assets/images/icons/arrowDown.png'
import { StandAloneAvatar } from 'components/Avatars'
import JoinTheTeam from 'components/JoinTheTeam'
import Modal, { CloseButton } from 'components/Modal'
import { avatars } from 'constants/'
import Blog from 'pages/Home/Blog'
import { tagIds } from 'services'
import { Desktop, TabletAndBelow } from 'styles'

import {
  Wrapper,
  Header,
  TextWrapper,
  SubHeader,
  Icon,
  HubspotStyleWrapper,
} from './styled-components'

const Done = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
    document.body.scrollTo(0, 0)
  }, [])

  const { setPathPageView } = useTrackingCode()

  const [modalOpen, setModalOpen] = React.useState(true)

  React.useEffect(() => {
    setPathPageView(window.location.pathname)
  }, [setPathPageView])

  const urlEncodedData = window.location.pathname.split('/')[2]

  const decodedData = atob(urlEncodedData)

  const type = decodedData.split('/')[0]

  const usersName = decodedData.split('/')[1].replace(/%20/g, ' ')

  if (!type || !usersName) {
    window.location.href = '/'
  }

  const titleCase = (str: string) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const { color, video } = avatars.filter(
    (avatar) => avatar.to === `/${type}`
  )[0]

  let tags

  if (type === 'schools') {
    tags = [tagIds.schools]
  } else {
    tags = [tagIds.publishers]
  }

  const hubspotBookingCode = (
    <HubspotStyleWrapper>
      <CloseButton onClick={() => setModalOpen(false)}>X</CloseButton>
      <div
        className="meetings-iframe-container"
        data-src="https://meetings.hubspot.com/riley-walker?embed=true"
      />
    </HubspotStyleWrapper>
  )

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
        />
      </Helmet>
      <Wrapper>
        <StandAloneAvatar color={color} video={video} size={[214, 242]} />
        <TextWrapper>
          <Header>Thank you {titleCase(usersName as string)}</Header>
          <Modal open={modalOpen} setOpen={setModalOpen}>
            {hubspotBookingCode}
          </Modal>
          <TabletAndBelow>
            <SubHeader>
              We look forward to meeting with you, please check your inbox.
              <br />
              <br />
              In the meantime, here's some content that may interest you:
            </SubHeader>
          </TabletAndBelow>
          <Desktop>
            <SubHeader>
              We look forward to meeting with you, please check your inbox.
              <br />
              In the meantime, here's some content that may interest you:
            </SubHeader>
          </Desktop>
        </TextWrapper>
        <Icon alt="arrow down" src={arrowDown} />
      </Wrapper>
      <Blog noTitle options={{ tagIds: tags }} />
      <Desktop>
        <JoinTheTeam />
      </Desktop>
    </>
  )
}

export default Done
