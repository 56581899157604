import { Link } from 'react-router-dom'

import { up, down } from 'styled-breakpoints'
import styled from 'styled-components'
import { colors } from 'styles'

export const Wrapper = styled.div<{ windowWidth: number }>`
  height: 366px;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: scroll;
  width: ${({ windowWidth }) =>
    `calc(100% - ((${windowWidth}px / 2 - 121px) * 2))`};
  padding-left: ${({ windowWidth }) => `calc(${windowWidth}px / 2 - 121px)`};
  padding-right: ${({ windowWidth }) => `calc(${windowWidth}px / 2 - 121px)`};
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  ${up('md')} {
    justify-content: center;
  }

  ${down('md')} {
    z-index: 1;

    > a > div {
      z-index: -2;
      > video {
        z-index: -1;
      }
    }
  }

  ${up('lg')} {
    height: 421px;
    width: calc(100% - 488px);
    padding-left: 244px;
    padding-right: 244px;
    padding-top: 75px;
    padding-bottom: 161px;
    overflow: hidden;

    > a {
      z-index: 1;
    }
  }
`

export const Video = styled.video<{ size: number }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  min-width: ${({ size }) => `${size}px`};
  min-height: ${({ size }) => `${size}px`};

  ${up('lg')} {
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    min-width: ${({ size }) => `${size}px`};
    min-height: ${({ size }) => `${size}px`};
  }
`

export const BackgroundWrapper = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 50%;
  overflow: hidden;
  display: flex;
`

export const Label = styled(Link)`
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${colors.white};
  background: radial-gradient(
      83.94% 83.94% at 26.39% 20.83%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    ),
    #000;
  border: none;
  position: absolute;
  border-radius: 109px;
  bottom: -20px;
  width: 242px;
  height: 56px;
  text-decoration: none;

  ${up('lg')} {
    bottom: -112px;
    width: 250px;
    height: 60px;
    font-size: 18px;
  }
`

export const VideoLabelWrapper = styled(Link)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 242px;
  height: 242px;
  max-width: 242px;
  max-height: 242px;
  text-decoration: none;
  cursor: pointer;
  :not(:last-child) {
    margin-right: 22px;
  }

  ${up('lg')} {
    width: 420px;
    height: 421px;
    max-width: 420px;

    :not(:last-child) {
      margin-right: 62px;
    }
  }
`
