import { useMutation } from 'react-query'

import { UseMutationOptions } from 'hooks/utils'
import QuestionsService from 'services/QuestionsService'

export function useCreateSubmission(
  options: UseMutationOptions<typeof QuestionsService.createSubmission> = {}
) {
  return useMutation(QuestionsService.createSubmission, options)
}
