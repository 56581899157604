import React from 'react'

import { colors } from 'styles'
import { useBreakpoints } from 'styles'

import Blob from './Blob'
import { StyleWrapper, Circle } from './styled-components'

// Define a type for the Circle configuration
type CircleConfig = {
  color: string
  size: [number, number]
  right?: [number, number]
  top?: [number, number]
  left?: [number, number]
  bottom?: [number, number]
}

const CircleConfigurations: CircleConfig[] = [
  {
    color: colors.burntOrange,
    size: [363, 692],
    right: [-177, -227],
    top: [-105, -67],
  },
  {
    color: colors.burntOrange,
    size: [19, 36],
    right: [190, 482],
    top: [49, 183],
  },
  { color: colors.black, size: [247, 470], left: [-91, -152], top: [11, -77] },
  { color: colors.black, size: [29, 39], left: [161, 340], top: [147, 99] },
  {
    color: colors.yellow,
    size: [191, 364],
    left: [-64, 53],
    bottom: [160, -51],
  },
  {
    color: colors.yellow,
    size: [25, 32],
    left: [110, 203],
    bottom: [320, 416],
  },
  { color: colors.black, size: [74, 141], right: [-10, 84], bottom: [360, 74] },
]

const BackgroundOverlay: React.FC = () => {
  const { isTabletOrBelow } = useBreakpoints()

  return (
    <StyleWrapper>
      {CircleConfigurations.map((config, index) => (
        <Circle
          size={isTabletOrBelow ? config.size[0] : config.size[1]}
          key={index}
          right={isTabletOrBelow ? config.right?.[0] : config.right?.[1]}
          top={isTabletOrBelow ? config.top?.[0] : config.top?.[1]}
          left={isTabletOrBelow ? config.left?.[0] : config.left?.[1]}
          bottom={isTabletOrBelow ? config.bottom?.[0] : config.bottom?.[1]}
          isTabletOrBelow={isTabletOrBelow}
          color={isTabletOrBelow ? config.color : 'transparent'}
        >
          {!isTabletOrBelow && (
            <Blob
              color={config.color}
              radius={isTabletOrBelow ? config.size[0] : config.size[1] / 2}
              markers={false}
              height={isTabletOrBelow ? config.size[0] : config.size[1] + 100}
            />
          )}
        </Circle>
      ))}
    </StyleWrapper>
  )
}

export default BackgroundOverlay
