//TODO: lift these to common components
import { Header as DefaultHeader } from 'pages/Home/styled-components'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { colors } from 'styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: none;
  overflow: hidden;
  align-items: center;
`

export const Header = styled(DefaultHeader)`
  text-align: center;
  margin: 0;
  margin-top: 150px;
`

export const SubHeader = styled.h2`
  color: ${colors.white};
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  max-width: 370px;
  margin-bottom: 80px;

  ${up('lg')} {
    max-width: 600px;
    font-size: 20px;
  }
`

export const ServicesContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  ${up('lg')} {
    margin-bottom: 40px;
    transform: none !important;
  }
`

// TODO: remove margin bottom from me in the future when animation is added
export const ServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ color }) => color};
  min-width: 340px;
  width: 340px;
  height: 260px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;

  /* fixes jagged outline in mobile safari */
  outline: 1px solid transparent;

  :nth-child(odd) {
    transform: rotate(3deg);
  }

  :nth-child(even) {
    transform: rotate(-3deg);
  }

  :last-child {
    margin-bottom: 15px;
  }

  ${up('lg')} {
    margin-bottom: 40px;
    transform: none !important;
    margin: 0 5px 40px 5px;
    :last-child {
      margin: 0 5px 40px 5px;
    }
  }
`

export const ServiceHeader = styled.span`
  color: ${colors.white};
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`

export const ServiceDescription = styled.span`
  color: ${colors.white};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const ServiceImage = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 14px;
`

export const HeroImage = styled.img`
  max-height: 538px;
  margin-left: 22%;
  display: none;
  z-index: 1;

  ${up('lg')} {
    display: block;
  }
`
