import { Container } from './styled-components'

const ScrollText = () => (
  <Container>
    <div className="scroll">
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
      Educate. Plan. Aspire. Educate. Plan. Aspire. Educate. Plan. Aspire.
    </div>
  </Container>
)

export default ScrollText
