import * as React from 'react'

import { avatars } from 'constants/avatars'
import { isSafariOrIosChrome, useBreakpoints } from 'styles'

import { useWindowSize } from './hooks'
import {
  Label,
  Video,
  VideoLabelWrapper,
  Wrapper,
  BackgroundWrapper,
} from './styled-components'
import { characters } from './types'

export const StandAloneAvatar = ({
  color,
  video,
  size,
}: {
  color: characters['color']
  video: characters['video']
  size: [number, number]
}) => {
  const { isTabletOrBelow } = useBreakpoints()

  const dimensions = isTabletOrBelow ? size[0] : size[1]

  return (
    <BackgroundWrapper color={color}>
      <Video playsInline autoPlay muted preload="auto" loop size={dimensions}>
        <source
          src={video}
          type={isSafariOrIosChrome ? 'video/quicktime' : 'video/webm'}
        />
      </Video>
    </BackgroundWrapper>
  )
}

const Avatars = () => {
  const { width } = useWindowSize()
  return (
    <Wrapper windowWidth={width}>
      {avatars.map(({ color, video, name, to }: characters) => (
        <VideoLabelWrapper key={name} to={to}>
          <StandAloneAvatar color={color} video={video} size={[242, 420]} />
          <Label to={to}>{name}</Label>
        </VideoLabelWrapper>
      ))}
    </Wrapper>
  )
}

export default Avatars
