import { SubHeader as DefaultSubHeader } from 'pages/Home/Services/styled-components'
import { Header as DefaultHeader } from 'pages/Home/styled-components'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { colors } from 'styles'

export const Wrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 93px;

  ${up('lg')} {
    margin-top: 151px;
  }
`

export const Header = styled(DefaultHeader)`
  margin: 0;
`

export const SubHeader = styled(DefaultSubHeader)`
  margin-top: 10px;
  margin-bottom: 35px;
  font-size: 16px;

  ${up('lg')} {
    font-size: 20px;
    margin-top: 32px;
    margin-bottom: 80px;
  }
`

export const BlogPostWrapper = styled.div`
  width: 272px;
  min-width: 272px;
  text-align: left;

  margin: auto;

  :nth-child(1) {
    margin-left: 20px;
  }
  :nth-child(2) {
    margin: 0 20px;
  }
  :nth-child(3) {
    margin-right: 20px;
  }

  ${up('lg')} {
    width: 380px;
    min-width: 380px;
    margin: 0;
  }
`

export const BlogPostConatiner = styled.div`
  background-color: ${colors.white};
  border-radius: 25px;
  overflow: hidden;
`

export const BlogTitle = styled.h3<{ minHeight: number }>`
  color: ${colors.black};
  margin: 20px;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  min-height: ${({ minHeight }) => minHeight}px;
  display: flex;
  align-items: center;

  ${up('lg')} {
    font-size: 28px;
  }
`

export const BlogExcerpt = styled.p`
  color: ${colors.black};
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  margin: 20px;
  margin-top: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  height: 50px;
  ${up('lg')} {
    font-size: 16px;
    display: flex;
    height: auto;
  }
`

export const BlogLink = styled.a`
  text-decoration: none;
  cursor: pointer;
`

export const BlogDate = styled.p`
  color: ${colors.white};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
  margin-left: 5px;
  margin-bottom: 10px;
`

//TODO: combine with button in valueprop styled-compontents and lift to commmon with variants
export const Button = styled.a.attrs({
  target: '_blank',
})`
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-self: center;
  color: ${colors.white};
  background: radial-gradient(
      83.94% 83.94% at 26.39% 20.83%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    ),
    #000;
  border: none;
  border-radius: 109px;
  width: 75%;
  height: 56px;
  text-decoration: none;
  margin-top: 85px;
  cursor: pointer;
  margin-bottom: 90px;

  ${up('lg')} {
    height: 60px;
    font-size: 18px;
    padding: 0 30px;
    width: calc(259px - 60px);
    margin-top: 80px;
    margin-bottom: 130px;
  }
`

export const ScrollContainer = styled.div`
  display: flex;
  width: 100%;
  overflow-x: scroll;
  margin: 0 auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  ${up('lg')} {
    justify-content: space-evenly;
  }
`
