import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { colors } from 'styles'

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 110%;
  height: 90px;
  transform: rotate(-3deg);
  -webkit-transform: rotate(-3deg);
  -webkit-backface-visibility: hidden;
  flex-shrink: 0;
  background-color: ${colors.black};
  color: ${colors.white};
  margin-left: -10px;
  font-family: Poppins;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  white-space: nowrap;
  padding-left: 10px;
  overflow: hidden;
  margin-top: -65px;
  z-index: 1;
  padding: 1px;

  /* fixes jagged outline in mobile safari */
  outline: 1px solid transparent;

  .scroll {
    animation: slide-left 300s linear infinite;

    @keyframes slide-left {
      from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
      }
      to {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
      }
    }
  }

  ${up('lg')} {
    height: 132px;
    font-size: 75px;
    transform: rotate(-2deg);
  }
`
