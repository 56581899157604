import * as React from 'react'

import { TabletAndBelow, Desktop } from 'styles'

import HamburgerMenu from './HamburgerMenu'
import { Wrapper, LogoLink, NavWrapper, Link } from './styled-components'

const Header = () => {
  return (
    <Wrapper id="top">
      <LogoLink to="/#top">ryco.io</LogoLink>
      <TabletAndBelow>
        <HamburgerMenu />
      </TabletAndBelow>
      <Desktop>
        <NavWrapper>
          <Link to="/#services">services</Link>

          <Link to="/#blog">blog</Link>

          <Link to="/#about">about</Link>
        </NavWrapper>
      </Desktop>
    </Wrapper>
  )
}

export default Header
