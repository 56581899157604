import React from 'react'

import vrGuy from 'assets/images/services/VRguy.png'
import service1 from 'assets/images/services/service1.png'
import service2 from 'assets/images/services/service2.png'
import service3 from 'assets/images/services/service3.png'
import service4 from 'assets/images/services/service4.png'
import service5 from 'assets/images/services/service5.png'
import service6 from 'assets/images/services/service6.png'
import service7 from 'assets/images/services/service7.png'
import service8 from 'assets/images/services/service8.png'
import { colors, Desktop } from 'styles'

import {
  Container,
  Header,
  SubHeader,
  ServicesContainer,
  ServiceContainer,
  ServiceHeader,
  ServiceDescription,
  ServiceImage,
  HeroImage,
} from './styled-components'

const services = [
  {
    image: service3,
    title: 'Interactives',
    subTitle: 'Gamify content learning',
    color: colors.hotPink,
  },
  {
    image: service2,
    title: 'Lessons & Activities',
    subTitle: 'Tailored to your classroom',
    color: colors.magenta,
  },
  {
    image: service7,
    title: 'Supplemental Materials',
    subTitle: 'Books, enrichments, & more',
    color: colors.lightBlue,
  },
  {
    image: service8,
    title: 'Audio + Voiceover',
    subTitle: 'Available in any language',
    color: colors.burntOrange,
  },

  {
    image: service6,
    title: 'Video, Graphics & Animation',
    subTitle: 'Bring stories to life',
    color: colors.green,
  },

  {
    image: service1,
    title: 'Consultation & Ideation',
    subTitle: 'Talk with real teachers',
    color: colors.blue,
  },
  {
    image: service4,
    title: 'Virtual & Augmented Reality',
    subTitle: 'Immersive learning, realized',
    color: colors.lightPurple,
  },
  {
    image: service5,
    title: 'Accessible Technology',
    subTitle: 'Including every learner',
    color: colors.yellow,
  },
]

const Services = () => {
  return (
    <Container>
      <Header id="services">
        Making Dreams{' '}
        <Desktop>
          <br />
        </Desktop>{' '}
        a Reality
      </Header>
      <SubHeader>
        We develop both digital and print content along with supporting
        materials for every classroom. If you're unsure about your specific
        needs, we can help!
      </SubHeader>
      <ServicesContainer>
        {services.map((service, idx) => (
          <Service key={idx} service={service} />
        ))}
      </ServicesContainer>
      <HeroImage src={vrGuy} alt="A Guy with a virtual reality headset on" />
    </Container>
  )
}

const Service = ({ service }: { service: (typeof services)[0] }) => {
  const { image, title, subTitle, color } = service
  return (
    <ServiceContainer color={color}>
      <ServiceImage alt={`${title} ${subTitle}`} src={image} />
      <ServiceHeader>{title}</ServiceHeader>
      <ServiceDescription>{subTitle}</ServiceDescription>
    </ServiceContainer>
  )
}

export default Services
