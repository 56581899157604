export const testimonials = [
  {
    author: 'Joanna Shelly, Associate Vice President of Curriculum',
    company: 'Commonwealth Charter Academy',
    quote:
      'Perhaps one of my favorite things about ryco is that their SME and leadership team are always suggesting ideas. A fresh point of view and perspective can help my team generate an approach or idea for a lesson that will keep the learner engaged and successful. ryco sincerely wants the best for our learners and seeks to elevate our learner experience.',
  },
  {
    author: 'John Broussard',
    role: 'Assistant Principal, Author, and Educator',
    quote: `What we create helps me feel empowered that I have the tools necessary to have a lasting impact. I had a backround in Curriculum and Instruction but there's no way I could've come up with the deliverables that they created.`,
  },
  {
    author: 'Geri Smith, Managing Editor for Digital Initiatives',
    company: 'Loyola Press',
    quote:
      'One of the greatest advantages of working with ryco.io is that they did not need a lot of handholding…Their ability to create content and deliver it, expertly crafted according to sound instructional design principles, allowed me to allocate my limited internal resources to other projects.',
  },
]
