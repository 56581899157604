/*============================================
               IMPORTS / SETUP
=============================================*/
import { QueryClient } from 'react-query'

/*============================================
                SERVICE DEFINTION
=============================================*/
const getConfiguredQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        // default to 10 minutes
        staleTime: 1000 * 60 * 10,
      },
    },
  })

export default getConfiguredQueryClient()
export { getConfiguredQueryClient }
