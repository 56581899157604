import React from 'react'

import val1 from 'assets/images/valueProps/val1.png'
import val2 from 'assets/images/valueProps/val2.png'
import val3 from 'assets/images/valueProps/val3.png'
import val4 from 'assets/images/valueProps/val4.png'
import { HUBSPOT_RILEY } from 'constants/links'

import {
  Header,
  Tagline,
  Container,
  Image,
  ImagesContainer,
  ImageTextContainer,
  ImageLabel,
  Button,
} from './styled-components'

const ValueProp = () => {
  const valProps = [
    {
      img: val1,
      text: 'Embrace Inclusion',
    },
    {
      img: val2,
      text: 'Create Engaging Content',
    },
    {
      img: val3,
      text: 'Empower Teachers, Save Time',
    },
    {
      img: val4,
      text: 'Improve Student Outcomes',
    },
  ]
  return (
    <Container>
      <Header>
        Created by <br /> Experienced Teachers
      </Header>
      <Tagline>Customized Solutions for Schools</Tagline>
      <ImagesContainer>
        {valProps.map((val, idx) => (
          <ImageTextContainer key={idx}>
            <Image alt={val.text} src={val.img} />
            <ImageLabel>{val.text}</ImageLabel>
          </ImageTextContainer>
        ))}
      </ImagesContainer>

      <Button target="_blank" href={HUBSPOT_RILEY}>
        Schedule a call with us
      </Button>
    </Container>
  )
}

export default ValueProp
